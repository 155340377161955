import { ACCOUNT_PURPOSES, TRANSACTION_STATUS, ACCOUNT_SUMMARY_STATUS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { ActionPanelActionsType, ActionPanelActionType, ActionPanelType, ExtraSectionType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';
import { captureMessageThen } from '@archinsurance-viki/property-jslib/src/utils/ui-helpers';
import { VIRA_MODAL_TYPES } from '../../constants/Modal';
import { QUOTE_TRANSITION_STATUSES } from '../../constants/Quote';
import { BACKGROUND_TASK_TYPES } from '../../constants/TaskConstants';
import { NEEDS_ENDORSEMENT_PACKAGE_TRANSACTION_TYPES, TRANSACTION_TYPES } from '../../constants/TransactionConstants';
import { AppContextFnsType, AppContextType } from '../../ts-types/AppTypes';
import { SubmissionDataType, TransactionDataType } from '../../ts-types/DataTypes';
import { filterCoverageOptions } from '../../utils/quote-helpers';
import { useIsRae } from '../../hooks/context';

export const CLOSED_STATUSES = ['DECLINED', 'NOT_ACCEPTED', 'QUOTES_WITHDRAWN'];

const SUBMISSION_BOUND_MESSAGE = 'Submission is bound and uneditable.';

const submissionIsInReview = (submission: SubmissionDataType) => {
    return submission && submission.status === 'UW_REVIEW';
};

export function submissionIsBound(submission: SubmissionDataType) {
    return submission && submission.status === 'BOUND';
}

export function originalSubmissionIsUnboundOrRelockable(submission: SubmissionDataType) {
    return !submissionIsBound(submission) || (submission._actions && submission._actions.relockable === true);
}

const canBeUWReview = (currentSubmission: SubmissionDataType | null, currentQuote: Record<string, any>) => {
    if (currentSubmission?.is_validated_async) {
        return currentSubmission?.is_valid_for_uw_review;
    }

    if (!currentSubmission?._validations?.ERROR || !currentQuote) {
        return false;
    }

    let filteredValidations = filterCoverageOptions(currentSubmission, currentQuote);
    return filteredValidations.ERROR.CAN_BE_UW_REVIEW?.length === 0;
};

const canBind = (currentSubmission: SubmissionDataType | null, currentQuote: Record<string, any>) => {
    if (!currentSubmission || !currentSubmission._validations || !currentSubmission._validations.ERROR || !currentQuote) {
        return false;
    }

    let filteredValidations = filterCoverageOptions(currentSubmission, currentQuote);

    return filteredValidations.ERROR.CAN_BE_BOUND?.length === 0;
};

const canQuote = (currentSubmission: SubmissionDataType | null, currentQuote: Record<string, any>) => {
    if (!currentSubmission || !currentSubmission._validations || !currentSubmission._validations.ERROR || !currentQuote) {
        return false;
    }

    let filteredValidations = filterCoverageOptions(currentSubmission, currentQuote);

    return filteredValidations.ERROR.CAN_BE_QUOTED?.length === 0;
};

const canPreviewQuote = (currentSubmission: SubmissionDataType | null, currentQuote: Record<string, any>) => {
    if (!currentSubmission || !currentSubmission._validations || !currentSubmission._validations.ERROR || !currentQuote) {
        return false;
    }

    let filteredValidations = filterCoverageOptions(currentSubmission, currentQuote);

    return filteredValidations.ERROR.CAN_PREVIEW_QUOTE?.length === 0;
};

const canPriceCat = (currentSubmission: SubmissionDataType | null, currentQuote: Record<string, any>) => {
    if (!currentSubmission || !currentSubmission._validations || !currentSubmission._validations.ERROR || !currentQuote) {
        return false;
    }

    let filteredValidations = filterCoverageOptions(currentSubmission, currentQuote);
    return filteredValidations.ERROR.CAN_PRICE_CAT?.length === 0;
};

const canIssuePolicy = (currentSubmission: SubmissionDataType | null, currentQuote: Record<string, any>) => {
    if (!currentSubmission || !currentQuote) {
        return false;
    }

    if (!currentSubmission?._validations?.ERROR) {
        return true; // if we can't validate, it's because the submission has already been issued
    }

    let filteredValidations = filterCoverageOptions(currentSubmission, currentQuote);
    return filteredValidations.ERROR.CAN_ISSUE_POLICY.length === 0;
};

const hasValidationError = (currentSubmission: SubmissionDataType, currentQuote: Record<string, any>) => {
    if (!currentSubmission?._validations?.ERROR) {
        return false;
    }

    let filteredValidations = filterCoverageOptions(currentSubmission, currentQuote);
    return filteredValidations.ERROR.CAN_PRICE_CAT?.length !== 0;
};

export function submissionIs(submission: SubmissionDataType | null, property: string): boolean {
    if (!submission || !submission._actions) {
        return false;
    }
    return !!submission._actions[property];
}

export function submissionIsEditable(submission: SubmissionDataType | null): boolean {
    return submissionIs(submission, 'editable');
}

export function submissionIsValidatable(submission: SubmissionDataType | null): boolean {
    return submissionIs(submission, 'validatable');
}

export function transactionIsPremiumBearing(transaction: Record<string, any> | null): boolean {
    if (!transaction) {
        return true; // if no transaction, assume it's not bound yet, and as such, must be premium-bearing.
    }
    return transaction.is_premium_bearing;
}

export function transactionIsPremiumBearingAndNotRollback(transaction: TransactionDataType | null): boolean {
    if (!transaction) {
        return true; // if no transaction, assume account is not bound yet, and as such, must be premium-bearing.
    }
    return transaction.is_premium_bearing && transaction.transaction_type !== 'RET' && transaction.transaction_type !== 'REV';
}

export const ACTION_PANEL_LOCATIONS = {
    SUBMISSION_LOG: 'SL',
    TRANSACTIONS: 'TR',
    OVERVIEW: 'OV',
    COVERAGE_OPTIONS_LIST: 'COL',
    DOCUMENTS: 'DOCS',
    EXPOSURE_SUMMARY: 'ES',
    TIV_GUIDELINES: 'TG',
    QUOTE_CONDITIONS: 'QC',
    BUILDING_QUOTE_CONDITIONS: 'BQC',
    BUILDING_LIMITS: 'BL',
};

const notIsPremiumBearingReasons = ['This transaction is non-premium bearing.'];
const cannotPriceCat = ['Cat Pricing is blocked.'];

type propTypes = {
    submissionRows?: (number | 'loading')[];
    appContextFns: AppContextFnsType;
    appContext: AppContextType;
};

export function calculateRowActions({ appContext, appContextFns }: propTypes, location: string, additionalSection?: ExtraSectionType): () => ActionPanelType {
    let {
        currentQuote,
        currentQuoteId,
        currentAccount,
        // currentAccountId,
        currentSubmission,
        currentSubmissionId,
        currentTransaction,
        currentTransactionId,
        featureFlags,
        tenantFlags,
        ENV,
        me,
    } = appContext;

    const isCancelled = currentAccount !== undefined && !!currentAccount.cancellation_date;

    return () => {
        let panelRowActions = {} as ActionPanelActionsType;
        let extraSections: ExtraSectionType[] = [];

        if (additionalSection) {
            extraSections.push(additionalSection);
        }

        const accountActions: ActionPanelActionType[] = [];
        const transactionActions: ActionPanelActionType[] = [];
        const submissionActions: ActionPanelActionType[] = [];
        const quoteActions: ActionPanelActionType[] = [];
        const isRAE = useIsRae();

        panelRowActions.addAction = location === ACTION_PANEL_LOCATIONS.SUBMISSION_LOG;
        if (!currentSubmission) {
            return { panelRowActions, extraSections };
        }

        const isOriginalSubmission = currentSubmission.account_purpose === ACCOUNT_PURPOSES.ORIGINAL_SUBMISSION;
        const isEndorsement = !isOriginalSubmission;
        const isOriginalOrRewrite = isOriginalSubmission || (currentTransaction && ['NBR', 'RBR'].includes(currentTransaction.transaction_type as string));
        const isOriginalAndPricedButUnbound = isOriginalSubmission && !submissionIsBound(currentSubmission) && currentQuote && currentQuote.final_premium;
        const isRewriteAndNotOpen =
            currentTransaction && ['NBR', 'RBR'].includes(currentTransaction.transaction_type as string) && currentTransaction.status !== 'OPEN';
        const { _actions = {} } = currentSubmission;
        const isPolicyBooked = !!currentSubmission.booked_by_id;

        const isRaeAndBoundCurrentSubmission = isRAE && submissionIsBound(currentSubmission);

        // let isPremiumBearing = transactionIsPremiumBearing(currentTransaction);
        const isPremiumBearingAndNotRollback = transactionIsPremiumBearingAndNotRollback(currentTransaction);
        const canRunCatPricing = !(_actions && _actions.validatable) || canPriceCat(currentSubmission, currentQuote);
        // if more than one transaction on the account, disable by default, assuming user is not on transactions page
        let disableLayered = ((currentAccount && currentAccount.account_transaction_ids) || []).length > 1;
        // VIKI-9148: if on transactions page, disable if selected transaction is REV/RET
        if (window.location.pathname.includes('transactions')) {
            disableLayered = currentTransaction && ['REV', 'RET'].includes(currentTransaction.transaction_type);
        }
        // Account Actions
        if (currentSubmission && currentSubmission._actions && currentSubmission._actions.transaction_initiatable && featureFlags.init_endorsement) {
            accountActions.push({
                label: 'Initiate Transaction',
                action: appContextFns.onInitiateTransaction,
                id: 'initiate-transaction',
                visible: tenantFlags.pricing,
            });
        }

        if (_actions.renewable === true) {
            if (isCancelled) {
                accountActions.push({
                    label: 'Renew Account',
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.RENEW_SUBMISSION, currentSubmission.id),
                    action: captureMessageThen({
                        title: 'Renew Account',
                        prompt: 'Please provide a reason for renewing a canceled account:',
                        onSave: result => appContextFns.onRenewSubmission(currentSubmissionId, result.message),
                        saveButtonCaption: 'Renew Account',
                        required: true,
                    }),
                    id: 'renew-submission',
                    visible: tenantFlags.pricing,
                });
            } else {
                accountActions.push({
                    label: 'Renew Account',
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.RENEW_SUBMISSION, currentSubmission.id),
                    action: appContextFns.onRenewSubmission,
                    id: 'renew-submission',
                    visible: tenantFlags.pricing,
                });
            }
        }

        // RIKI-3703 - Update the 'Do Not Renew' Flag to be at Customer level
        // Originally from VENT-12469 - Mark accounts for non-renewal/do not write
        const new_status = currentSubmission.is_insured_flag ? false : true;
        if (_actions.insured_flag === true) {
            accountActions.push({
                label: currentSubmission.is_insured_flag ? 'Unflag Insured' : 'Mark as "Insured Flagged" status',
                action: captureMessageThen({
                    title: currentSubmission.is_insured_flag ? 'Unflag Insured' : 'Mark as "Insured Flagged" status',
                    prompt: 'Please provide a reason:',
                    onSave: result => appContextFns.onChangeInsuredFlag(currentSubmissionId, new_status, result.message),
                    saveButtonCaption: 'Save',
                    required: true,
                }),
                disabled: isRaeAndBoundCurrentSubmission,
                disabledReasons: SUBMISSION_BOUND_MESSAGE,
                id: 'change-insured-flag',
            });
        }

        if (featureFlags?.enable_loss_run_generator && currentSubmission && (currentSubmission.policy_number || currentSubmission.renewal_of_id)) {
            accountActions.push({
                label: 'Generate Loss Run Report',
                id: 'lossrun',
                description: 'Generate a loss run report.',
                action: () => appContextFns.onGenerateLossRunReport(currentSubmissionId),
                visible: tenantFlags.pricing,
            });
        }

        if (currentQuote) {
            const isValidatedAndHasNoQuotePreviewingErrorsOrEndorsement =
                !(_actions && _actions.validatable) || canPreviewQuote(currentSubmission, currentQuote) || isEndorsement;

            let accountSummaryOnDemand = null;
            if (isOriginalAndPricedButUnbound || (isEndorsement && currentTransaction && currentTransaction.status === TRANSACTION_STATUS.IN_REVIEW)) {
                accountSummaryOnDemand = ACCOUNT_SUMMARY_STATUS.PRE_BIND;
            }
            if (submissionIsBound(currentSubmission) || (isEndorsement && currentTransaction && currentTransaction.status === TRANSACTION_STATUS.BOUND)) {
                accountSummaryOnDemand = ACCOUNT_SUMMARY_STATUS.POST_BIND;
            }
            if (accountSummaryOnDemand) {
                accountActions.push({
                    label: 'Generate Account Summary',
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.CREATE_ACCOUNT_SUMMARY, currentSubmission.id),
                    action: () => {
                        appContextFns.onGenerateAccountSummary(currentSubmission.id, currentTransactionId, accountSummaryOnDemand);
                    },
                    id: 'generate-account-summary',
                    disabled: !isValidatedAndHasNoQuotePreviewingErrorsOrEndorsement,
                    disabledReasons: ['Cannot generate a Account Summary because the quote/transaction has validation errors.'],
                    description: 'Generate Account Summary on demand.',
                    visible: tenantFlags.pricing,
                });
            }
        }

        let isNotRollback = !!currentSubmission?.id;
        if (currentTransaction && ['RET', 'REV'].includes(currentTransaction.transaction_type)) isNotRollback = false;

        if (isNotRollback) {
            submissionActions.push({
                label: 'Clone Submission',
                action: appContextFns.onDuplicateSubmission,
                actionFnData: currentSubmissionId,
                progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.CLONE_SUBMISSION, currentSubmission.id),
                id: 'duplicate',
                throttle: 500,
                disabled: isRaeAndBoundCurrentSubmission,
                disabledReasons: SUBMISSION_BOUND_MESSAGE,
            });
            if (
                ENV.TENANT_SETTINGS.cat_model_settings.show_cat_model_export_button ||
                featureFlags?.enable_rae_cat_model_input_file_export ||
                !featureFlags?.streamline_rae_uw_tasks_one_click
            ) {
                submissionActions.push({
                    label: 'Export Cat Model Input Files',
                    action: appContextFns.onExportCatData,
                    actionFnData: currentSubmissionId,
                    id: 'export-cat-data',
                    disabled: !isPremiumBearingAndNotRollback,
                    disabledReasons: notIsPremiumBearingReasons,
                    throttle: 500,
                });
            }
        }

        if (currentSubmission.id) {
            if (_actions.relockable === true) {
                submissionActions.push({
                    label: 'Relock after Correction & Regenerate Binder',
                    description: 'Complete account correction, relock submission, and regenerate binder.',
                    // disabledReasons: currentSubmission._actions.relockable,
                    action: captureMessageThen({
                        title: 'Relock and Regenerate Binder',
                        prompt: 'Optionally, provide a reason:',
                        onSave: result => appContextFns.onRelockSubmission(currentSubmissionId, result.message),
                        saveButtonCaption: 'Relock',
                        required: false,
                    }),
                    id: 'relock-submission',
                });
            }

            if (_actions.unlockable === true && !isRAE) {
                submissionActions.push({
                    label: 'Unlock for Account Correction',
                    description: 'Allow edits to be made to this quote without unbinding.',
                    // disabled_reasons: currentSubmission._actions.unlockable,
                    action: captureMessageThen({
                        title: 'Unlock for Account Correction',
                        prompt: 'Please provide a reason:',
                        onSave: result =>
                            appContextFns.onUnlockSubmission(currentSubmissionId, result.message).then(() => {
                                appContextFns.history.push(`/submissions/${currentSubmissionId}/current/underwriting/${currentQuoteId}`);
                            }),
                        saveButtonCaption: 'Unlock',
                        required: true,
                    }),
                    id: 'unlock-submission',
                });
            }

            let isClosed = CLOSED_STATUSES.includes(currentSubmission.status);
            if (isClosed) {
                submissionActions.push({
                    label: 'Reopen Submission',
                    action: appContextFns.onReOpenSubmission,
                    id: 're-open',
                });
            } else if (currentSubmission.status) {
                // clearance
                if (currentSubmission.status === 'NEW_SUBMISSION') {
                    submissionActions.push({
                        label: 'Send Submission To Data Entry',
                        action: appContextFns.onSendToDataEntry,
                        actionFnData: currentSubmissionId,
                        id: 'send-to-data-entry',
                        throttle: 2000,
                    });
                    submissionActions.push({
                        label: 'Send Submission To Data Entry As Rush',
                        action: appContextFns.onSendToDataEntryAsRush,
                        actionFnData: currentSubmissionId,
                        id: 'send-to-data-entry-as-rush',
                        throttle: 2000,
                    });
                }

                if (currentSubmission.status === 'ENTERING_DATA') {
                    if (isRAE && featureFlags?.streamline_rae_uw_tasks_one_click) {
                        submissionActions.push({
                            label: 'Push to PolicyWriter',
                            action: appContextFns.onProceedToUwReview,
                            actionFnData: currentSubmissionId,
                            id: 'proceed-to-uw-review',
                            throttle: 2000,
                            disabled: !canBeUWReview(currentSubmission, currentQuote),
                        });
                    } else {
                        submissionActions.push({
                            label: 'Send To UW Review',
                            action: appContextFns.onSendToUwReview,
                            actionFnData: currentSubmissionId,
                            id: 'send-to-uw-review',
                            throttle: 2000,
                        });
                    }
                }

                if (isRAE && currentSubmission.has_buildings) {
                    if (featureFlags?.enable_pw_download) {
                        submissionActions.push({
                            label: 'Preview Data to PolicyWriter',
                            action: appContextFns.onExportPolicyWriter,
                            actionFnData: currentSubmissionId,
                            id: 'export-to-pw-spreadsheet',
                            throttle: 500,
                        });
                    }
                    if (!featureFlags?.streamline_rae_uw_tasks_one_click) {
                        submissionActions.push({
                            label: 'Upload Data To PolicyWriter',
                            action: appContextFns.onUploadPolicyWriter,
                            actionFnData: currentSubmissionId,
                            id: 'upload-to-pw-service',
                            throttle: 500,
                            disabled: submissionIsBound(currentSubmission) || !currentSubmission.is_pw_uploadable,
                            disabledReasons: [
                                submissionIsBound(currentSubmission) ? SUBMISSION_BOUND_MESSAGE : null,
                                !currentSubmission.is_pw_uploadable ? 'Submission does not have Arch ID' : null,
                            ].filter(v => !!v),
                        });
                        submissionActions.push({
                            label: 'Export Data To Strategic Analytics',
                            action: appContextFns.onUploadStrategicAnalytics,
                            actionFnData: currentSubmissionId,
                            id: 'upload-to-sa-service',
                            throttle: 500,
                            disabled: !currentSubmission.is_sa_uploadable,
                        });
                    }
                }

                // rush data entry
                if (currentSubmission.is_rush && currentSubmission.status === 'ENTERING_DATA') {
                    submissionActions.push({
                        label: 'Remove Submission From Rush',
                        action: appContextFns.onSendToDataEntry,
                        actionFnData: currentSubmissionId,
                        id: 'send-to-data-entry',
                        throttle: 2000,
                    });
                }

                // data entry
                if (!currentSubmission.is_rush && currentSubmission.status === 'ENTERING_DATA') {
                    submissionActions.push({
                        label: 'Mark Submission As Rush',
                        action: appContextFns.onSendToDataEntryAsRush,
                        actionFnData: currentSubmissionId,
                        id: 'send-to-data-entry-as-rush',
                        throttle: 500,
                    });
                }

                // underwriting
                if (submissionIsInReview(currentSubmission)) {
                    submissionActions.push({
                        label: isRAE ? 'Unlock RAE Data Entry' : 'Send Back To Data Entry As Rush',
                        action: appContextFns.onSendToDataEntryAsRush,
                        actionFnData: currentSubmissionId,
                        id: 'send-to-data-entry-as-rush',
                    });
                }

                if (submissionIsInReview(currentSubmission) && featureFlags.enable_automated_eb_referral_email) {
                    submissionActions.push({
                        label: 'Refer EB Price to Boiler Re',
                        // description: "",
                        action: captureMessageThen({
                            title: 'Send EB Price Referral Request to Boiler Re',
                            prompt: 'Please provide a reason:',
                            onSave: result => appContextFns.onReferEBPrice(currentSubmissionId, result.message),
                            saveButtonCaption: 'Send Referral Request',
                            required: true,
                        }),
                        id: 'refer-eb-price',
                    });
                }

                //TODO: Might be a better way to handle this in tenant settings or some other idea
                //Run CAT Models button displays for different statuses in RAE as opposed to VIKI. Existing functionality for VIKI displays the button when submission is in UW_REVIEW or BOUND and editable (Binder Revision).
                //In RAE, we cannot display in UW_REVIEW because that is the end of the line in RAE. When a submission reaches UW_REVIEW the data has already been sent to PolicyWriter.
                //If changes need to be made the requirement is the submission should be moved back to Data Entry, changes are made, and then processed back to PolicyWriter and UW_REVIEW.
                const showCatModels =
                    submissionIsInReview(currentSubmission) ||
                    (!isRAE && submissionIsBound(currentSubmission)) ||
                    (isRAE && featureFlags?.enable_rae_cat_modeling && window.location.pathname.includes('modeling') && !submissionIsBound(currentSubmission));

                if (isOriginalSubmission && submissionIsEditable(currentSubmission) && showCatModels) {
                    submissionActions.push({
                        label: 'Run Cat Models',
                        action: appContextFns.onRunCatModels,
                        description: 'Open a dialog to select quotes for running cat models',
                        id: 'touchstone',
                        progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.SUBMIT_TO_TOUCHSTONE, currentSubmission.id),
                        disabled: !isPremiumBearingAndNotRollback || !canRunCatPricing,
                        disabledReasons: !canRunCatPricing ? cannotPriceCat : notIsPremiumBearingReasons,
                    });

                    if (currentSubmission.is_live_production_submission === false) {
                        submissionActions.push({
                            label: 'Fake Cat Models (for testing only)',
                            action: appContextFns.onFakeCatModels,
                            id: 'fake-cat-models',
                            disabled: !isPremiumBearingAndNotRollback || !canRunCatPricing,
                            disabledReasons: !canRunCatPricing ? cannotPriceCat : notIsPremiumBearingReasons,
                        });
                    }
                }

                // bound
                if (!submissionIsBound(currentSubmission)) {
                    submissionActions.push({
                        label: 'Close Submission',
                        action: appContextFns.onOpenCloseSubmissionModal,
                        actionFnData: currentSubmissionId,
                        id: 'close',
                        throttle: 500,
                    });
                }

                if (currentSubmission.status === 'ENTERING_DATA' && isRAE && featureFlags?.streamline_rae_uw_tasks_one_click) {
                    submissionActions.push({
                        label: 'Validation Errors/Warnings Check',
                        action: appContextFns.onValidateForUwReview,
                        actionFnData: currentSubmissionId,
                        id: 'validate-for-uw-review',
                        throttle: 2000,
                        disabled: !currentSubmission.is_validated_async || canBeUWReview(currentSubmission, currentQuote),
                        disabledReasons: 'The submission is either under 1000 locations or validation process has already been run on the account.',
                    });
                }
            }

            if (['Pending', 'Unknown', 'Error'].includes(currentSubmission.ofac_status) && currentTransaction?.status === 'BOUND')
                submissionActions.push({
                    label: 'Override OFAC',
                    action: appContextFns.onOpenOverrideOfacModal,
                    actionFnData: currentSubmissionId,
                    id: 'override_ofac',
                });

            if (
                currentTransaction &&
                ['NB', 'NBR', 'RB', 'RBR', 'NBV', 'RBV', 'RET'].includes(currentTransaction.transaction_type) &&
                currentTransaction.status === 'BOUND' &&
                !!isPolicyBooked
            ) {
                const isValid = canIssuePolicy(currentSubmission, currentQuote);
                const hasMoreRecentPolicy = currentTransaction.id !== currentAccount.most_recent_unreverted_transaction.id;

                const { disabled, disabledReasons } = {
                    disabled: hasMoreRecentPolicy || !isValid,
                    disabledReasons: hasMoreRecentPolicy
                        ? "Issuing this policy isn't allowed because a more recent policy exists."
                        : 'Policy Issuance blocked due to validation errors.',
                };

                submissionActions.push({
                    label: 'Preview Policy Issuance',
                    action: appContextFns.onPreviewPolicyIssuance,
                    actionFnData: currentSubmissionId,
                    id: 'preview-policy-issuance',
                    disabled,
                    disabledReasons,
                    visible: tenantFlags.pricing,
                });

                submissionActions.push({
                    label: currentTransaction.issuance_date === null ? 'Issue Policy' : 'Reissue Policy',
                    action: appContextFns.onIssuePolicy,
                    actionFnData: currentSubmissionId,
                    id: 'policy-issuance',
                    disabled,
                    disabledReasons,
                    visible: tenantFlags.pricing,
                });
            }
        }

        if (currentTransaction && isEndorsement) {
            if (currentTransaction.status !== TRANSACTION_STATUS.OPEN) {
                transactionActions.push({
                    label: 'Show Pricing',
                    action: appContextFns.onPriceEndorsement,
                    actionFnData: currentTransaction,
                    id: 'price-endorsement',
                    disabled: !isPremiumBearingAndNotRollback,
                    disabledReasons: notIsPremiumBearingReasons,
                    visible: tenantFlags.pricing,
                });
            }

            if (currentTransaction.status === TRANSACTION_STATUS.OPEN) {
                transactionActions.push({
                    label: 'Move to Review',
                    id: 'move-to-review',
                    action: appContextFns.onMoveEndorsementToReview,
                    actionFnData: currentTransactionId,
                });
            }

            if (currentTransaction.status === TRANSACTION_STATUS.IN_REVIEW) {
                transactionActions.push({
                    label: 'Bind Endorsement',
                    id: 'bind-endorsement',
                    action: appContextFns.onBindEndorsement,
                    actionFnData: currentTransaction,
                    disabled: currentSubmission && currentQuote && hasValidationError(currentSubmission, currentQuote),
                });
                transactionActions.push({
                    label: 'Run Cat Models',
                    action: appContextFns.onRunCatModels,
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.SUBMIT_TO_TOUCHSTONE, currentSubmission.id),
                    id: 'touchstone',
                    disabled: !isPremiumBearingAndNotRollback || !canRunCatPricing,
                    disabledReasons: !canRunCatPricing ? cannotPriceCat : notIsPremiumBearingReasons,
                });

                if (currentSubmission.is_live_production_submission === false) {
                    transactionActions.push({
                        label: 'Fake Cat Models (for testing only)',
                        action: appContextFns.onFakeCatModels,
                        // progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.SUBMIT_TO_TOUCHSTONE, currentSubmission.id),
                        id: 'fake-cat-models',
                        disabled: !isPremiumBearingAndNotRollback || !canRunCatPricing,
                        disabledReasons: !canRunCatPricing ? cannotPriceCat : notIsPremiumBearingReasons,
                    });
                }

                const canTransitionToOpen = currentTransaction._actions.reopenable === true;
                if (canTransitionToOpen) {
                    transactionActions.push({
                        label: 'Move back to Open',
                        id: 'move-to-review',
                        action: appContextFns.onMoveEndorsementToOpen,
                        actionFnData: currentTransactionId,
                    });
                }
            }

            if (currentTransaction.status === TRANSACTION_STATUS.BOUND) {
                transactionActions.push({
                    label: 'Unbind Endorsement',
                    action: appContextFns.onUnbindEndorsement,
                    actionFnData: currentTransactionId,
                    id: 'unbind',
                    disabled: currentTransaction._actions.unbindable !== true,
                    disabledReasons: currentTransaction._actions.not_unbindable_reason,
                });
            }

            if (currentTransaction.status !== TRANSACTION_STATUS.BOUND) {
                transactionActions.push({
                    label: 'Discard Endorsement',
                    action: appContextFns.onDiscardEndorsement,
                    actionFnData: currentTransaction.id,
                    id: 'discard-endorsement',
                });
            }

            if (
                (NEEDS_ENDORSEMENT_PACKAGE_TRANSACTION_TYPES as string[]).includes(currentTransaction.transaction_type) &&
                [TRANSACTION_STATUS.IN_REVIEW, TRANSACTION_STATUS.BOUND].includes(currentTransaction.status)
            ) {
                transactionActions.push({
                    label: currentTransaction.status === TRANSACTION_STATUS.IN_REVIEW ? 'Preview Endorsement Package' : 'Reissue Endorsement Package',
                    action: appContextFns.onGenerateEndorsementPackage,
                    actionFnData: currentTransaction.id,
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.ENDORSEMENT_PACKAGE, currentTransactionId || -1),
                    id: 'generate-endorsement-package',
                    visible: tenantFlags.pricing,
                });
            }
        }

        if (currentTransaction && currentTransaction._actions) {
            transactionActions.push({
                label: 'Export Schedule of Locations',
                action: appContextFns.onExportScheduleOfLocations,
                actionFnData: currentTransactionId,
                id: 'schedule-of-locations',
                disabled: currentTransaction._actions.schedule_of_locations_exportable !== true,
                disabledReasons: currentTransaction._actions.not_schedule_of_locations_exportable_reason,
            });
            if (currentTransaction._actions.bookable) {
                transactionActions.push({
                    label: 'Book',
                    id: 'book-transaction',
                    description: 'Set the booking flag.',
                    actionFnData: currentTransactionId,
                    action: appContextFns.onBookTransaction,
                });
            }
            if (currentTransaction._actions.unbookable) {
                transactionActions.push({
                    label: 'Unbook',
                    id: 'unbook-transaction',
                    description: 'Undo the booking flag.',
                    actionFnData: currentTransactionId,
                    action: appContextFns.onUnbookTransaction,
                    visible: tenantFlags.pricing,
                });
            }
        }

        if (
            currentTransaction &&
            ([TRANSACTION_TYPES.SHORT_RATE_CANCELLATION, TRANSACTION_TYPES.NON_FLAT_RATE_CANCELLATION] as string[]).includes(
                currentTransaction.transaction_type
            ) &&
            currentTransaction.status === TRANSACTION_STATUS.IN_REVIEW
        ) {
            transactionActions.push({
                label: 'Change Cancellation Effective Date',
                id: 'change-cancellation-effective-date',
                description: 'Change effective date of a cancellation',
                action: appContextFns.onOpenUpdateCancellationEffectiveDateModal,
                actionFnData: currentTransactionId,
            });
        }

        if (currentQuote) {
            const isValidatedAndHasNoBindingErrors = !(_actions && _actions.validatable) || canBind(currentSubmission, currentQuote);
            const isValidatedAndHasNoQuotingErrors = !(_actions && _actions.validatable) || canQuote(currentSubmission, currentQuote);
            const isValidatedAndHasNoQuotePreviewingErrors = !(_actions && _actions.validatable) || canPreviewQuote(currentSubmission, currentQuote);

            if (isRAE && featureFlags?.enable_rae_cat_modeling) {
                quoteActions.push({
                    label: 'RAE CAT Model Indicator',
                    action: () => {
                        appContextFns.onToggleRAECatModel(currentQuote.id, !currentQuote.rae_cat_model_indicator);
                    },
                    id: 'rae-cat-model-indicator',
                    description: 'Toggle RAE Cat modelling indicator for the coverage option',
                    throttle: 2000,
                    disabled: submissionIsBound(currentSubmission),
                    disabledReasons: `${SUBMISSION_BOUND_MESSAGE} Please send back to Data Entry to run CAT models.`,
                    toggle: [
                        { label: 'RAE CAT Model (Yes)', active: currentQuote.rae_cat_model_indicator },
                        { label: 'RAE CAT Model (No)', active: !currentQuote.rae_cat_model_indicator },
                    ],
                });
            }

            if (currentQuote.final_premium && !currentQuote.is_allowed_to_quote.is_allowed) {
                quoteActions.push({
                    label: 'Request CUO Approval',
                    action: () => {
                        appContextFns.onRequestCUOApproval(currentQuote.id);
                    },
                    id: 'request-approval',
                    disabled: currentQuote._actions.cuo_approval_requestable !== true,
                    disabledReasons: currentQuote._actions.not_cuo_approval_requestable_reason,
                    throttle: 2000,
                    visible: tenantFlags.pricing,
                });
            }

            // has fp
            // if os: not bound
            if (isOriginalAndPricedButUnbound) {
                quoteActions.push({
                    label: 'Preview Quote Letter',
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.QUOTE_PREVIEW, currentQuote.id),
                    action: () => {
                        appContextFns.onGenerateQuoteLetter(currentQuote.id, true, true);
                    },
                    id: 'preview-quote-letter',
                    disabled: !isValidatedAndHasNoQuotePreviewingErrors,
                    disabledReasons: ['Cannot preview a quote letter because the quote has validation errors.'],
                    description: 'Generate a preview of the quote letter for this quote.',
                    visible: tenantFlags.pricing,
                });

                quoteActions.push({
                    label: 'Mark as Sent & Generate Quote Letter',
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.QUOTE_LETTER, currentQuote.id),
                    action: () => {
                        appContextFns.onGenerateQuoteLetter(currentQuote.id, false, true);
                    },
                    id: 'generate-quote-letter',
                    disabled: !isValidatedAndHasNoQuotingErrors,
                    disabledReasons: ['Cannot generate a quote letter because the quote has validation errors.'],
                    description: 'Mark this quote as Sent to Agent and generate a quote letter.',
                    visible: tenantFlags.pricing,
                });
            }

            if ((originalSubmissionIsUnboundOrRelockable(currentSubmission) || isRewriteAndNotOpen) && me.user_type !== 'DATA_ENTRY') {
                const isPreviewable = isValidatedAndHasNoQuotingErrors && (currentSubmission._actions || {}).binder_previewable === true;
                let previewableDisabledReasons: string | string[] = [];
                if (!isValidatedAndHasNoQuotingErrors) previewableDisabledReasons = ['Cannot generate a binder because the quote has validation errors.'];
                else previewableDisabledReasons = (currentSubmission._actions || {}).not_binder_previewable_reason;

                quoteActions.push({
                    label: 'Preview Binder',
                    action: appContextFns.onGeneratePreviewBinder,
                    actionFnData: currentQuoteId,
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.BINDER_PREVIEW, currentQuoteId || -1),
                    id: 'preview-binder',
                    disabled: !isPreviewable,
                    disabledReasons: previewableDisabledReasons,
                    description: 'Generate a preview of the binder document for this quote.',
                    visible: tenantFlags.pricing,
                });
            }

            if (isOriginalOrRewrite && !submissionIsBound(currentSubmission) && me.user_type !== 'DATA_ENTRY') {
                quoteActions.push({
                    label: 'Mark As Bound & Generate Binder',
                    action: () => {
                        const onSuccess = () => {
                            appContextFns.onGenerateBinder(currentQuoteId);
                        };
                        appContextFns.onPopBindConfirmationModal(currentQuoteId, currentSubmissionId, currentQuote, onSuccess);
                    },
                    actionFnData: currentQuoteId,
                    id: 'bound',
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.BINDER_AGREEMENT, currentQuoteId || -1),
                    disabled: !isValidatedAndHasNoBindingErrors,
                    disabledReasons: ['Cannot generate a binder because the quote has validation errors.'],
                    description: 'Bind this quote and generate a binder document.',
                    visible: tenantFlags.pricing,
                });
            }

            if (location === ACTION_PANEL_LOCATIONS.COVERAGE_OPTIONS_LIST) {
                if (
                    originalSubmissionIsUnboundOrRelockable(currentSubmission) &&
                    !isEndorsement &&
                    currentQuote.status !== 'WITHDRAWN' &&
                    currentQuote.status !== 'NOT_TAKEN_UP'
                ) {
                    quoteActions.push({
                        label: 'Mark As Primary',
                        action: () => {
                            appContextFns.onMarkQuoteAsPrimary(currentSubmissionId, currentQuoteId);
                        },
                        id: 'mark-as-primary',
                        disabled: currentQuoteId === currentSubmission.primary_quote_id,
                        disabledReasons: 'This quote is already primary.',
                    });
                }

                if (!submissionIsBound(currentSubmission)) {
                    quoteActions.push({
                        label: 'Duplicate Quote',
                        action: () => {
                            appContextFns.onDuplicateQuote(currentSubmissionId, currentQuoteId);
                        },
                        id: 'duplicate-quote',
                    });

                    quoteActions.push({
                        label: 'Delete Quote',
                        action: () => {
                            appContextFns.onDeleteQuote(currentSubmissionId, currentQuoteId);
                        },
                        id: 'delete-quote',
                    });
                }
            }

            if (!submissionIsBound(currentSubmission) && !isEndorsement && currentQuote.status !== 'WITHDRAWN') {
                quoteActions.push({
                    label: 'Mark As Withdrawn',
                    action: (_data, _target, actionHelperFns) => {
                        appContextFns.onPopCloseQuoteMessageModal(currentQuote.id, QUOTE_TRANSITION_STATUSES.WITHDRAWN.STATUS, actionHelperFns);
                    },
                    id: 'withdrawn',
                });
            }

            if (!submissionIsBound(currentSubmission) && !isEndorsement && currentQuote.status !== 'NOT_TAKEN_UP') {
                quoteActions.push({
                    label: 'Mark As Not Taken Up',
                    action: (_data, _target, actionHelperFns) => {
                        appContextFns.onPopCloseQuoteMessageModal(currentQuote.id, QUOTE_TRANSITION_STATUSES.NOT_TAKEN_UP.STATUS, actionHelperFns);
                    },
                    id: 'not-taken-up',
                });
            }

            if (!isEndorsement && currentQuote.status === 'BOUND' && me.user_type !== 'DATA_ENTRY' && !isRAE) {
                quoteActions.push({
                    label: 'Unbind Quote',
                    action: (_data, _target, actionHelperFns) => {
                        appContextFns.onUnbindSubmission(currentSubmission.id, actionHelperFns);
                    },
                    id: 'unbind',
                    description: 'Undo the binding of this submission.',
                    disabled: _actions.unbindable !== true,
                    disabledReasons: _actions.not_unbindable_reason,
                });
            }
        }

        if (accountActions.length) {
            extraSections.push({
                title: 'Account Actions',
                actions: accountActions,
            });
        }

        if (transactionActions.length) {
            transactionActions.push({
                label: 'Edit Layer Participation Form',
                action: data => appContextFns.onOpenCenteredModal(data, VIRA_MODAL_TYPES.LAYERED_POLICIES),
                actionFnData: { quoteId: currentQuoteId },
                id: 'layered-policies',
                disabled: disableLayered,
                disabledReasons: 'Go To Transactions',
                description: disableLayered ? '' : 'Policies for Layered Accounts - to print on VT0153',
                visible: tenantFlags.pricing,
            });
            extraSections.push({
                title: 'Transaction Actions',
                actions: transactionActions,
            });
        }

        if (location === ACTION_PANEL_LOCATIONS.SUBMISSION_LOG) {
            panelRowActions.deleteAction = currentSubmission && currentSubmission.props && !currentSubmission.props.readonly;

            panelRowActions.additionalActions = submissionActions;
        } else {
            extraSections.push({
                title: 'Submission Actions',
                actions: submissionActions,
            });
        }

        if (quoteActions.length) {
            extraSections.push({
                title: 'Quote Actions',
                actions: quoteActions,
            });
        }

        return { panelRowActions, extraSections };
    };
}
