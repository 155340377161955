import cx from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { TRANSACTION_PULLDOWN_STATES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { VentusNumberFormatOptions, formatNumberIntl } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useQuoteId, useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import api from '../../services/apiSlice';
import { DefaultAccountLayout } from '../../components/common/DefaultLayout';
import { FinalPremiumType, LossByRegionAndPerilResult, PolicyCoverageType } from '../../ts-types/ApiTypes';
import { getSharedAndLayeredDescription, trimAndParse } from '../../features/coverage-terms/utils/coverage-utils';
import BasicInput from '@archinsurance-viki/property-jslib/src/components/inputs/v2/BasicInput';
import { BodyCell, HeaderCell, HeaderRow, Table, TableRow } from '@archinsurance-viki/property-jslib/src/components/table/Table';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import { useCatAnalysisQuery, useOsmPmlTableQuery } from '../../services/endpoints/coverage-option';
import { useAppContext } from '../../hooks/context';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';
import { ThinBottomPanelWrapper } from '@archinsurance-viki/property-jslib/src/containers/panels/ThinBottomPanelWrapper';
import { useValidateQuery } from '../../services/endpoints/policy-coverage';
import { ValidationsTable } from '../ValidationsPanel';
import { usePrevUpdate } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { ARCH_PML_AAL_UW_GUIDELINES_EQ_URL, ARCH_PML_AAL_UW_GUIDELINES_FLOOD_URL, ARCH_PML_AAL_UW_GUIDELINES_WIND_URL } from '../../constants/Navigation';

const currencyFormatOptions: Partial<VentusNumberFormatOptions> = { maximumFractionDigits: 0, minimumFractionDigits: 0, useGrouping: true };
const rateFormatOptions: Partial<VentusNumberFormatOptions> = { maximumFractionDigits: 2, minimumFractionDigits: 2, useGrouping: true };

const InfoBlockWithTextBorder = ({ label, children }: { label: string; children?: React.ReactNode }) => (
    <fieldset className="tw-border-2 tw-border-gray-600 tw-px-2 tw-py-4 tw-text-base">
        <legend className="tw-tracking-wide tw-w-[unset] tw-text-xs tw-ps-1 tw-pe-1">{label}</legend>
        {children}
    </fieldset>
);

const getPerils = (policyCoverage: PolicyCoverageType) => {
    const perils = [];
    if (!policyCoverage?.wind_or_hail_excluded) {
        perils.push('Wind');
    }
    if (policyCoverage.is_earthquake_included) {
        perils.push('Earthquake');
    }
    if (policyCoverage.is_flood_included) {
        perils.push('Flood');
    }
    if (policyCoverage.aop) {
        perils.push('AOP');
    }
    return perils.join(', ');
};

const CoverageOptionSummary = ({
    policyCoverage: {
        policy_is_layered,
        occurrence_participation_fmt,
        occurrence_participation,
        occurrence_participation_dollar_amt,
        occurrence_attachment,
        occurrence_limit,
    },
    finalPremium,
}: {
    finalPremium: FinalPremiumType;
    policyCoverage: PolicyCoverageType;
}) => {
    const sharedAndLayeredDescription = getSharedAndLayeredDescription(
        {
            policy_is_layered,
            occurrence_participation_fmt,
            occurrence_participation: +occurrence_participation,
            occurrence_participation_dollar_amt: trimAndParse(occurrence_participation_dollar_amt),
            occurrence_attachment: trimAndParse(occurrence_attachment),
            occurrence_limit: trimAndParse(occurrence_limit),
        },
        +finalPremium?.tiv
    );
    const { policyCoverage } = useQuotePolicyCoverage();
    const { currentAccountId } = useAppContext();
    return (
        <InfoBlockWithTextBorder label="Coverage Option Summary">
            <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-flex tw-gap-2">
                    <span>Total Broker TIV:</span>
                    <div>{formatNumberIntl(+finalPremium.total_building_tiv, currencyFormatOptions)}</div>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span>Total Pricing TIV:</span>
                    <div>{formatNumberIntl(+finalPremium.total_pricing_tiv, currencyFormatOptions)}</div>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span>Perils Selected:</span>
                    <div>{getPerils(policyCoverage)}</div>
                </div>

                <BasicInput
                    className="[&>input]:tw-text-left [&>input]:tw-truncate tw-max-w-[300px]"
                    title={sharedAndLayeredDescription}
                    value={sharedAndLayeredDescription}
                    disabled
                />

                <div />

                <p className="tw-flex tw-items-center tw-gap-2">
                    <Icon icon="info" className=" tw-text-blue-primary" />
                    <Link to={`/submissions/${currentAccountId}/current/editor/`}>
                        <a className="tw-border-b">See Buildings page for location level AALs</a>
                    </Link>
                </p>
            </div>
        </InfoBlockWithTextBorder>
    );
};

const Suggestions = ({
    peril,
    policyCoverage: {
        policy_is_layered,
        occurrence_participation_fmt,
        occurrence_participation,
        occurrence_participation_dollar_amt,
        occurrence_attachment,
        occurrence_limit,
        attachment_layer_name,
    },
    finalPremium,
}: {
    peril: PerilType;
    finalPremium: FinalPremiumType;
    policyCoverage: PolicyCoverageType;
}) => {
    if (peril === 'earthquake' || peril === 'flood') {
        return null;
    }

    const sharedAndLayeredDescription = getSharedAndLayeredDescription(
        {
            policy_is_layered,
            occurrence_participation_fmt,
            occurrence_participation: +occurrence_participation,
            occurrence_participation_dollar_amt: trimAndParse(occurrence_participation_dollar_amt),
            occurrence_attachment: trimAndParse(occurrence_attachment),
            occurrence_limit: trimAndParse(occurrence_limit),
        },
        +finalPremium?.tiv
    );
    const attachmentRecommendation = attachment_layer_name
        ? `${sharedAndLayeredDescription} is in the ${attachment_layer_name.toLowerCase()}`
        : "layer will be provided once the AIR response and OSM PML's/Factors are received";

    return (
        <div className="tw-flex tw-flex-col">
            <div className="tw-flex tw-justify-center tw-items-center">
                <h2 className="main-label tw-text-lg tw-flex tw-items-center">
                    <Icon className="tw-text-orange-600 tw-text-2xl" icon="notifications" />
                    Guidance
                </h2>
            </div>
            <div className="tw-border tw-border-gray-600" />
            <span className="tw-flex tw-text-base tw-font-bold tw-px-2 tw-py-2">The coverage option {attachmentRecommendation}.</span>
        </div>
    );
};

const OSMSummary = ({ finalPremium }: { finalPremium: FinalPremiumType }) => {
    const quoteId = useQuoteId();
    const { data } = useOsmPmlTableQuery({ quoteId });
    return (
        <InfoBlockWithTextBorder label="OSM Summary">
            <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-flex tw-gap-2">
                    <span>OSM Ground UP PML with Surge:</span>
                    <div>{!isNullish(data?.group_pml_w_surge) ? formatNumberIntl(+data.group_pml_w_surge, currencyFormatOptions) : ''}</div>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span>OSM Ground UP PML without Surge:</span>
                    <div>{!isNullish(data?.group_pml_wout_surge) ? formatNumberIntl(+data.group_pml_wout_surge, currencyFormatOptions) : ''}</div>
                </div>
                <div />
                <Table className="tw-grid tw-grid-cols-6 [&_td]:tw-border-gray-600 [&_td]:tw-border-r [&_td]:tw-border-b [&_td:first-child]:tw-border-l">
                    <HeaderRow>
                        <HeaderCell># of Loc</HeaderCell>
                        <HeaderCell>Avg State Factor</HeaderCell>
                        <HeaderCell>Avg Constr. Factor</HeaderCell>
                        <HeaderCell>Avg Age Factor</HeaderCell>
                        <HeaderCell>Avg DTC Factor</HeaderCell>
                        <HeaderCell>Avg County Factor</HeaderCell>
                    </HeaderRow>
                    <TableRow>
                        <BodyCell>{finalPremium.number_of_buildings}</BodyCell>
                        <BodyCell>{!isNullish(data?.pml_state_factor) ? formatNumberIntl(+data.pml_state_factor, rateFormatOptions) : ''}</BodyCell>
                        <BodyCell>{!isNullish(data?.pml_cns_factor) ? formatNumberIntl(+data.pml_cns_factor, rateFormatOptions) : ''}</BodyCell>
                        <BodyCell>{!isNullish(data?.pml_age_factor) ? formatNumberIntl(+data.pml_age_factor, rateFormatOptions) : ''}</BodyCell>
                        <BodyCell>{!isNullish(data?.pml_dtc_factor) ? formatNumberIntl(+data.pml_dtc_factor, rateFormatOptions) : ''}</BodyCell>
                        <BodyCell>{!isNullish(data?.pml_county_factor) ? formatNumberIntl(+data.pml_county_factor, rateFormatOptions) : ''}</BodyCell>
                    </TableRow>
                </Table>
            </div>
        </InfoBlockWithTextBorder>
    );
};

const GrossAAL = ({ peril }: { peril: PerilType }) => {
    const quoteId = useQuoteId();
    const { data } = useCatAnalysisQuery({ quoteId });
    const gross_aal = data?.loss_by_peril?.[peril]?.gross_aal;
    return (
        <InfoBlockWithTextBorder label="AIR Gross AAL">
            <div className="tw-flex tw-flex-col tw-max-w-[500px]">
                <Table className="tw-grid tw-grid-cols-1">
                    <HeaderRow>
                        <HeaderCell>AIR Gross AAL</HeaderCell>
                    </HeaderRow>
                    <TableRow>
                        <BodyCell className="tw-border-r tw-border-l tw-border-b tw-border-gray-600">
                            {!isNullish(gross_aal) ? formatNumberIntl(+gross_aal, currencyFormatOptions) : ''}
                        </BodyCell>
                    </TableRow>
                </Table>
            </div>
        </InfoBlockWithTextBorder>
    );
};

const PMLSummaryGroundUp = ({ peril }: { peril: PerilType }) => {
    const quoteId = useQuoteId();
    const { data } = useCatAnalysisQuery({ quoteId });
    const return_period_250_ground_up_pml = data?.loss_by_peril?.[peril]?.return_period_250_ground_up_pml;
    const return_period_1000_ground_up_pml = data?.loss_by_peril?.[peril]?.return_period_1000_ground_up_pml;
    return (
        <InfoBlockWithTextBorder label="AIR PML Summary - Ground Up (No Layering or Deductible Applied)">
            <Table className="tw-grid tw-grid-cols-3">
                <HeaderRow>
                    <HeaderCell>Standard Deviation</HeaderCell>
                    <HeaderCell>250 Yr</HeaderCell>
                    <HeaderCell>1000 Yr</HeaderCell>
                </HeaderRow>
                <TableRow>
                    <BodyCell className="tw-border-r tw-border-l tw-border-b tw-border-gray-600">
                        {!isNullish(data?.standard_deviation) ? formatNumberIntl(+data?.standard_deviation, currencyFormatOptions) : ''}
                    </BodyCell>
                    <BodyCell className="tw-border-r tw-border-l tw-border-b tw-border-gray-600">
                        {!isNullish(return_period_250_ground_up_pml) ? formatNumberIntl(+return_period_250_ground_up_pml, currencyFormatOptions) : ''}
                    </BodyCell>
                    <BodyCell className="tw-border-r tw-border-b tw-border-gray-600">
                        {!isNullish(return_period_1000_ground_up_pml) ? formatNumberIntl(+return_period_1000_ground_up_pml, currencyFormatOptions) : ''}
                    </BodyCell>
                </TableRow>
            </Table>
        </InfoBlockWithTextBorder>
    );
};

const TAB_TO_PERIL_MAP = {
    0: 'wind',
    1: 'earthquake',
    2: 'flood',
} as const;

const PERIL_TO_GUIDELINES_MAP = {
    flood: { display: 'Flood', value: ARCH_PML_AAL_UW_GUIDELINES_FLOOD_URL },
    earthquake: { display: 'Earthquake', value: ARCH_PML_AAL_UW_GUIDELINES_EQ_URL },
    wind: { display: 'Named Windstorm', value: ARCH_PML_AAL_UW_GUIDELINES_WIND_URL },
} as const;

type PerilType = (typeof TAB_TO_PERIL_MAP)[keyof typeof TAB_TO_PERIL_MAP];

const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
        <div onClick={options.onClick} className={cx(options.className, 'table-pref', { active: options.selected })}>
            <i className="pi pi-prime p-mr-2" />
            {options.titleElement}
        </div>
    );
};

const PMLSummaryGross = ({ peril }: { peril: PerilType }) => {
    const quoteId = useQuoteId();
    const { data } = useCatAnalysisQuery({ quoteId });
    return (
        <InfoBlockWithTextBorder label="AIR PML Summary - Gross (Layering and Deductible Applied)">
            <Table className="tw-mt-2 tw-grid tw-grid-cols-3 [&_td]:tw-border-gray-600 [&_td]:tw-border-r [&_td]:tw-border-b [&_td:first-child]:tw-border-l">
                <HeaderRow>
                    <HeaderCell>Region</HeaderCell>
                    <HeaderCell>250 Yr</HeaderCell>
                    <HeaderCell>1000 Yr</HeaderCell>
                </HeaderRow>
                {(data?.loss_by_region_and_peril?.[peril] ?? []).map((loss: LossByRegionAndPerilResult<string>) => (
                    <TableRow>
                        <BodyCell>{loss.arch_region.name}</BodyCell>
                        <BodyCell>
                            {!isNullish(loss?.return_period_250_gross_pml) ? formatNumberIntl(+loss.return_period_250_gross_pml, currencyFormatOptions) : ''}
                        </BodyCell>
                        <BodyCell>
                            {!isNullish(loss?.return_period_1000_gross_pml) ? formatNumberIntl(+loss.return_period_1000_gross_pml, currencyFormatOptions) : ''}
                        </BodyCell>
                    </TableRow>
                ))}
            </Table>
        </InfoBlockWithTextBorder>
    );
};

export function ModelingAnalysisPage() {
    const dispatch = useAppDispatch();

    const [isValidationsOpen, setIsValidationsOpen] = useState(false);
    const [activePerilTab, setActivePerilTab] = useState(0);

    const showOsmPmls = useAppSelector(state => state.global.featureFlags?.show_osm_pmls_factors ?? true);
    const { currentQuote } = useAppContext();
    const quoteId = useQuoteId();
    const { finalPremium } = useQuoteFinalPremium();
    const { policyCoverage } = useQuotePolicyCoverage();

    const { isLoading, isUninitialized, isError } = useCatAnalysisQuery({ quoteId });
    const { validations } = useValidateQuery(
        { id: currentQuote?.policy_coverage_id },
        {
            skip: !currentQuote,
            selectFromResult: result => {
                const validationData = result?.data;
                if (!validationData) {
                    return { ...result, validations: [] };
                }
                const validations = [
                    ...Object.values(validationData.field_errors).map(errors => ({ message: errors.join('\n ') })),
                    ...validationData.non_field_errors.map(error => ({ message: error })),
                ];
                return { ...result, validations };
            },
        }
    );

    const isWaiting = !finalPremium || !policyCoverage || isLoading || isUninitialized;

    usePusherEvent(`quote-${quoteId}`, 'dirty', () => {
        dispatch(
            api.util.invalidateTags([
                { type: 'FinalPremium', id: quoteId },
                { type: 'QuoteValidation', id: quoteId },
                { type: 'CatAnalysis', id: quoteId },
            ])
        );
    });

    const hasValidations = validations.length > 0;
    usePrevUpdate(hasValidations, prevHasValidations => {
        if (hasValidations && !prevHasValidations) {
            setIsValidationsOpen(true);
        } else if (!hasValidations && prevHasValidations) {
            setIsValidationsOpen(false);
        }
    });

    if (isWaiting) {
        return (
            <DefaultAccountLayout transactionPulldown={TRANSACTION_PULLDOWN_STATES.ACCOUNT_CORRECTIONS}>
                <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
                    <Spinner size="xl" />
                </div>
            </DefaultAccountLayout>
        );
    }

    if (isError) {
        return (
            <DefaultAccountLayout transactionPulldown={TRANSACTION_PULLDOWN_STATES.ACCOUNT_CORRECTIONS}>
                <div className="tw-flex tw-h-full tw-px-8 tw-py-4">
                    <span className="tw-text-red">Could not load CAT data. Please try again.</span>
                </div>
            </DefaultAccountLayout>
        );
    }

    const renderPerilContent = (peril: PerilType) => (
        <div className="tw-flex tw-flex-col tw-gap-2">
            <a target="_blank" href={PERIL_TO_GUIDELINES_MAP[peril].value} className="main-label tw-text-lg tw-flex tw-w-fit tw-items-center tw-gap-1">
                <Icon className="tw-text-orange-600 tw-text-2xl" icon="link" />
                <span>{`Arch ${PERIL_TO_GUIDELINES_MAP[peril].display} Guidelines`}</span>
            </a>
            <div className="tw-flex tw-flex-col tw-gap-4">
                {showOsmPmls && peril === 'wind' && <OSMSummary finalPremium={finalPremium} />}
                <GrossAAL peril={peril} />
                <PMLSummaryGroundUp peril={peril} />
                <PMLSummaryGross peril={peril} />
            </div>
        </div>
    );

    return (
        <DefaultAccountLayout transactionPulldown={TRANSACTION_PULLDOWN_STATES.ACCOUNT_CORRECTIONS}>
            <div className="tw-w-10/12">
                <div className="tw-p-2 tw-flex tw-flex-col tw-gap-4 [&_th]:tw-p-1.5 first:[&_th]:tw-rounded-tl last:[&_th]:tw-rounded-tr [&_td]:tw-h-[45px]">
                    <div className="tw-flex tw-gap-4">
                        <div className="tw-flex-[2]">
                            <CoverageOptionSummary finalPremium={finalPremium} policyCoverage={policyCoverage} />
                        </div>
                        <div className="tw-flex-[3]">
                            <Suggestions peril={TAB_TO_PERIL_MAP[activePerilTab]} finalPremium={finalPremium} policyCoverage={policyCoverage} />
                        </div>
                    </div>
                    <TabView
                        activeIndex={activePerilTab}
                        onTabChange={e => setActivePerilTab(e.index)}
                        className="[&_.p-tabview-nav]:tw-max-w-[750px] [&_.p-tabview-nav]:tw-mb-2"
                    >
                        <TabPanel header="Wind" headerTemplate={tabTemplate}>
                            {renderPerilContent('wind')}
                        </TabPanel>
                        <TabPanel header="Earthquake" headerTemplate={tabTemplate}>
                            {renderPerilContent('earthquake')}
                        </TabPanel>
                        <TabPanel header="Flood" headerTemplate={tabTemplate}>
                            {renderPerilContent('flood')}
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            <ThinBottomPanelWrapper
                tabs={[`${validations.length} Error${validations.length !== 1 ? 's' : ''}`]}
                activeTabIndex={isValidationsOpen ? 0 : null}
                onTabSelect={() => setIsValidationsOpen(prev => !prev)}
                render={() => (
                    <div className="tw-flex tw-flex-col tw-gap-2 tw-h-[300px] tw-pb-8">
                        <h2>Validation Errors</h2>
                        <ValidationsTable validations={validations} />
                    </div>
                )}
            />
        </DefaultAccountLayout>
    );
}
